import { loadStripe } from "@stripe/stripe-js";

// segment button
export const SEGMENT_PAYMENT = "product";
export const SEGMENT_SUBSCRIPTION = "subscription"; // DEPRECATE
export const SEGMENT_PAYMENTPLAN = "paymentplan";

// price or product label in dropdown
export const LBL_PRODUCT = "product";
export const LBL_PRICE = "price";

// reducer actions
export const AS_SET_CUSTOMER = "setCustomer";
export const AS_CLEAR_CUSTOMER = "clearCustomer";

export const AS_SET_USER = "setUser";
export const AS_CLEAR_USER = "clearUser";

export const AS_SET_REPS = "setReps";

export const AS_SET_DEAL = "setDeal";
export const AS_CLEAR_DEAL = "clearDeal";
export const AS_SET_DEAL_ADDRESS = "setDealAddress";

export const AS_ADD_CARD = "addCard";
export const AS_SET_CARDS = "setCards";
export const AS_REMOVE_CARD = "removeCard";
export const AS_CLEAR_CARDS = "clearCards";
export const AS_SET_EXTRACARDS = "setExtraCards"; // FIXME only necessary while we have spurious stripe customers with same email

export const AS_SET_SCHEDULED_PAYMENTS = "setScheduledPayments";
export const AS_ADD_SCHEDULED_PAYMENT = "addScheduledPayment";
export const AS_REMOVE_SCHEDULED_PAYMENT = "removeScheduledPayment";
export const AS_MODIFY_SCHEDULED_PAYMENT = "modifyScheduledPayment";

export const PS_FAILED = "failed"; // charge failed. stuck.
export const PS_UNSCHEDULED = "unscheduled"; // charged, expected to fail (card expiry)
export const PS_SCHEDULED = "scheduled"; // charged, expected to succeed
export const PS_SUCCESS = "success"; // charged successfully
export const PS_HELD = "held"; // paused by sales rep
export const PS_DELETED = "deleted"; // paused=deleted by sales rep
export const PS_PENDING = "pending"; // backend currently processing

export const AS_SET_HSCONTACT = "setHsContact";
export const AS_CLEAR_HSCONTACT = "clearHsContact";

export const initialState = {
  customer: {
    id: "",
    name: "",
    email: "",
  },
  reps: [],
  cards: [],
  scheduledPayments: [],
};

export const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  disableLink: true,
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

// eslint-disable-next-line no-undef
export const stripePromise = loadStripe(process.env.REACT_APP_stripeCredential);

// selectDeal reducer
export const SET_DEALS = "setFoundDeals";
export const PICK_EMAIL = "chooseEmail";
export const SET_EMAILS = "setFoundEmails";

// redux tags
export const RT_STRIPECUSTOMER = "stripeCustomer";
// export const RT_PRODUCTS = "products"; // unused, but could come in handy in the future
export const RT_HUBSPOTCONTACT = "hubspotContact";
export const RT_SCHEDULEDPAYMENT = "scheduledPayment";
export const RT_PAYMENTMETHODS = "paymentMethods";
export const RT_DEALSFOREMAIL = "dealsForEmail";
export const RT_DEAL = "deal";
export const RT_TEAMMAPPINGS = "teamMappings";

// max number of times to retry searching for a contact (1 second intervals) before giving up
export const HUBSPOTRETRIES = 10;
