import {
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import {
  addDays,
  getYear,
  isAfter,
  isBefore,
  isMonday,
  isSameMonth,
  lastDayOfMonth,
  lastDayOfWeek,
  subDays,
  addMonths,
  addYears,
  format,
  parseISO,
  subMonths,
  subYears,
  getMonth,
} from "date-fns";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// date picker should allow to select dates from minDate or later
const ourFormat = (dateobject) => format(dateobject, "yyyy-MM-dd");

const buildDays = (showdate, selecteddate, minDate) => {
  // find month
  const showMonth = parseISO(showdate);
  const month = getMonth(showMonth) + 1;
  const year = getYear(showMonth);

  const paddedmonth = `${month}`.length > 1 ? `${month}` : `0${month}`;
  const firstdayofmonth = parseISO(`${year}-${paddedmonth}-01`);
  const lastdayofmonth = lastDayOfMonth(firstdayofmonth);

  const firstDayInCalendar = ourFormat(
    subDays(
      lastDayOfWeek(firstdayofmonth, {
        weekStartsOn: 1,
      }),
      // add + 1week if firstdayofmonth is monday (like may 2023)
      isMonday(firstdayofmonth) ? 13 : 6
    )
  );

  const days = [];
  for (let i = 0; i < 42; i++) {
    const thisdate = addDays(parseISO(firstDayInCalendar), i);
    const now = new Date();

    const date = ourFormat(thisdate);
    const thisMonthSameAsShowMonth = isSameMonth(showMonth, thisdate);

    const nextMonth = isAfter(thisdate, lastdayofmonth);
    const prevMonth = isBefore(thisdate, firstdayofmonth);

    const isToday = date === ourFormat(now);
    const isSelected = date === selecteddate;

    days.push({
      date,
      isToday,
      isSelected,
      isAvailable:
        isBefore(subDays(parseISO(minDate), 1), thisdate) && // must be mindate or future
        thisMonthSameAsShowMonth,
      prevMonth,
      nextMonth,
    });
  }
  return days;
};

const TWDatePicker = (props) => {
  const {
    onClose,
    onChange,
    defaultDate, // "YYYY-MM-DD", what is also being returned
    minDate, // normal Date() object
  } = props;

  const ourToday = () => ourFormat(new Date());

  const ourMinDate = ourFormat(minDate || new Date());
  const [selectedDate, setSelectedDate] = useState(
    defaultDate ? defaultDate.substring(0, 10) : ourFormat(new Date())
  );

  const firstSelectableDate =
    parseISO(ourMinDate) > parseISO(selectedDate) ? ourMinDate : selectedDate;

  // which month to show initially set to the selected date
  const [showDate, setShowDate] = useState(
    ourFormat(defaultDate ? parseISO(defaultDate) : new Date())
  );

  const [days, setDays] = useState(
    buildDays(showDate, firstSelectableDate, ourMinDate)
  );

  // update days array
  useEffect(() => {
    setDays(() => buildDays(showDate, firstSelectableDate, ourMinDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDate, selectedDate]);

  const adjustMonth = (positive) =>
    setShowDate((prev) =>
      ourFormat(
        positive ? addMonths(parseISO(prev), 1) : subMonths(parseISO(prev), 1)
      )
    );

  const adjustYear = (positive) =>
    setShowDate((prev) =>
      ourFormat(
        positive ? addYears(parseISO(prev), 1) : subYears(parseISO(prev), 1)
      )
    );

  const selectDay = (index) => {
    const picked = days[index];

    // day jumping
    if (!picked.isAvailable) {
      if (picked.nextMonth) {
        adjustMonth(true);
        return;
      }
      if (picked.prevMonth) {
        adjustMonth(false);
        return;
      }
      // same month, but unavailable
      return;
    }

    setSelectedDate(() => picked.date);

    if (onChange) {
      onChange(picked.date);
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      className={twMerge(
        "grid self-center grid-cols-7 gap-x-16 transition-all ease-out",
        props.className
      )}
    >
      <div
        className={twMerge(
          "col-start-1 col-end-8 row-start-1 mt-10 text-center"
        )}
      >
        <div className="flex items-center text-gray-900">
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 mr-2"
            onClick={() => adjustYear(false)}
            title="Previous year"
          >
            <span className="sr-only">Previous Year</span>
            <ChevronDoubleLeftIcon className="w-5 h-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            onClick={() => adjustMonth(false)}
            title="Previous month"
          >
            <span className="sr-only">Previous month</span>
            <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
          </button>
          <div
            className="flex-auto text-sm font-semibold cursor-pointer"
            title="Today"
            onClick={() => setShowDate(() => ourToday())}
          >
            {format(parseISO(showDate), "MMMM")}{" "}
            {format(parseISO(showDate), "yyyy")}{" "}
          </div>

          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            onClick={() => adjustMonth(true)}
            title="Next month"
          >
            <span className="sr-only">Next month</span>
            <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 ml-2"
            onClick={() => adjustYear(true)}
            title="Next year"
          >
            <span className="sr-only">Next year</span>
            <ChevronDoubleRightIcon className="w-5 h-5" aria-hidden="true" />
          </button>
        </div>
        <div className="grid grid-cols-7 mt-6 text-xs leading-6 text-gray-500">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
        <div className="grid grid-cols-7 gap-px mt-2 text-sm bg-gray-200 rounded-lg shadow isolate ring-1 ring-gray-200">
          {days.map((day, dayIdx) => (
            <button
              key={day.date}
              type="button"
              onClick={() => selectDay(dayIdx)}
              className={classNames(
                "py-1.5 hover:bg-blue-600 hover:text-white focus:z-10",
                day.isAvailable ? "bg-white" : "bg-gray-50",
                (day.isSelected || day.isToday) && "font-semibold",
                day.isSelected && "text-white",
                !day.isSelected &&
                  day.isAvailable &&
                  !day.isToday &&
                  "text-gray-900",
                !day.isSelected &&
                  !day.isAvailable &&
                  !day.isToday &&
                  "text-gray-400",
                day.isToday && !day.isSelected && "text-indigo-600",
                dayIdx === 0 && "rounded-tl-lg",
                dayIdx === 6 && "rounded-tr-lg",
                dayIdx === days.length - 7 && "rounded-bl-lg",
                dayIdx === days.length - 1 && "rounded-br-lg"
              )}
            >
              <time
                dateTime={day.date}
                className={classNames(
                  "mx-auto flex h-7 w-7 items-center justify-center rounded-full",
                  day.isSelected && day.isToday && "bg-indigo-600",
                  day.isSelected && !day.isToday && "bg-gray-900"
                )}
              >
                {day.date.split("-").pop().replace(/^0/, "")}
              </time>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TWDatePicker;
