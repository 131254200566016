import { useEffect, useState } from "react";
import { formatDollars } from "../util/stripe";
import { useCreateTaxTransactionMutation } from "../store/api";

// this is just a preview; none of these transactions are committed in Avalara.
// That should be done the moment the transaction has been
const DealTaxPreview = (props) => {
  const {
    chargeText,
    chargeAmount,
    address,
    tellTax,
    product,
    creditAmount = 0,
  } = props;

  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(+chargeAmount);

  const [createTaxTransaction] = useCreateTaxTransactionMutation();
  useEffect(() => {
    // turned tax OFF for now // TODO set to true to try and charge tax
    // const chargeTax = true;
    const chargeTax = false;

    // const chargeTax = product?.has_tax === "Yes";

    const chargeInclCredit =
      creditAmount > chargeAmount ? 0 : chargeAmount - creditAmount;

    // no tax? always show 0
    if (!chargeTax) {
      setTotal(() => chargeInclCredit);
      setTax(() => 0);
      tellTax(0);
      return;
    }

    if (!chargeAmount || !address || address.country !== "US") {
      return;
    }

    createTaxTransaction({ amount: chargeInclCredit, address })
      .then(({ data: tax }) => {
        const { total } = tax;
        setTax(() => total);
        tellTax(total);
        setTotal(() => +chargeInclCredit + total);
      })
      .catch(() => {
        setTax(() => 0);
        tellTax(0);
        setTotal(() => +chargeInclCredit);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeAmount, address, product, creditAmount]);

  return (
    <>
      <div className="mt-4 mb-2 text-blue-400">{chargeText}</div>
      <div className="flex ">
        <div className="flex-grow">Net</div>
        <div>{formatDollars(chargeAmount)}</div>
      </div>
      {!!creditAmount && (
        <div className="flex ">
          <div className="flex-grow">Credit</div>
          <div>{formatDollars(creditAmount)}</div>
        </div>
      )}
      <div className="flex ">
        <div className="flex-grow">Tax</div>
        <div>{formatDollars(tax)}</div>
      </div>
      <div className="flex font-bold">
        <div className="flex-grow">Total</div>
        <div>{formatDollars(total)}</div>
      </div>
    </>
  );
};

export default DealTaxPreview;
