import { twMerge } from "tailwind-merge";
import {
  SEGMENT_PAYMENT,
  SEGMENT_PAYMENTPLAN,
  // SEGMENT_SUBSCRIPTION,
} from "../store/constants";

const SegmentButton = (props) => {
  return (
    <div
      className={twMerge(
        "py-2 my-4 border-gray-100 border border-solid flex-1 text-center cursor-pointer",
        props.className,
        props.segment === props.value
          ? " bg-blue-100 border-blue-300"
          : "bg-gray-100",
        props.first && "rounded-tl rounded-bl",
        props.last && "rounded-tr rounded-br"
      )}
      onClick={props.setSegment ? () => props.setSegment(props.value) : null}
    >
      {props.children}
    </div>
  );
};

const SegmentButtons = (props) => {
  const segments = [
    {
      value: SEGMENT_PAYMENT,
      label: "Payment",
    },
    // {
    //   value: SEGMENT_SUBSCRIPTION,
    //   label: "Subscription",
    // },
    {
      value: SEGMENT_PAYMENTPLAN,
      label: "Payment plan",
    },
  ];

  const { selectedSegment, chooseSegment } = props;
  return (
    <div className="flex flex-row w-full">
      {segments.map((seg, index) => (
        <SegmentButton
          key={index}
          first={index === 0}
          last={index === segments.length - 1}
          value={seg.value}
          segment={selectedSegment}
          setSegment={chooseSegment}
        >
          {seg.label}
        </SegmentButton>
      ))}
    </div>
  );
};

export default SegmentButtons;
