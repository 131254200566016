import { Field } from "formik";

const VerbalAgreement = () => {
  return (
    <>
      <div className="mt-4 mb-2 text-blue-400">Verbal agreement</div>

      <div className="pt-2 text-sm text-justify text-gray-400">
        <Field
          type="checkbox"
          id="termsCheckbox"
          name="agree"
          className="p-1 cursor-pointer"
        />

        <label htmlFor="termsCheckbox" className="px-1 my-1 cursor-pointer ">
          By verbally acknowledging yes, you agree that Mindmint LLC may collect
          and use your email/phone number to discuss your account & share
          additional services or products via email, phone or text as set forth
          in our Privacy Policy, You also agree to our Terms & Conditions. Your
          data is 100% secured and will never be shared.
        </label>
      </div>
    </>
  );
};

export default VerbalAgreement;