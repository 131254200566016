import CardSelectItem from "./CardSelectItem";

const CardSelector = (props) => {
  const { cards, paymentMethod } = props;

  const setSource = (source) => {
    props.setCurrentSource(() => source);
  };
  
  return (
    <div>
      {(cards || []).map((entry) => (
        <CardSelectItem
          key={entry.id}
          id={entry.id}
          name={props.name}
          card={entry.card}
          checked={entry.id === paymentMethod}
          needsUpdate={entry.needsUpdate}
          setSource={setSource}
          onRemove={props.removeCard}
        />
      ))}{" "}
      <CardSelectItem
        new
        name={props.name}
        id="new"
        key="new"
        setSource={setSource}
        checked={paymentMethod === "new"}
      />{" "}
    </div>
  );
};

export default CardSelector;
