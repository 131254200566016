import { createSlice } from "@reduxjs/toolkit";

const adminReducer = createSlice({
  name: "admin",
  initialState: {
    userGroups: []
  },
});

export default adminReducer.reducer;
