import { formatDollars } from "../util/stripe";

export const DealsList = (props) => {
  const { deals, pickDeal, isFetching } = props;

  if (isFetching) {
    return (
      <ul>
        <i>Loading deals…</i>
      </ul>
    );
  }

  return (
    <ul>
      {deals &&
        deals.map((deal) => (
          <li
            onClick={() => pickDeal(deal.id)}
            className="p-2 my-1 border border-solid rounded cursor-pointer"
            key={deal.id}
          >
            {formatDollars(deal.amount || 0)}{" "}
            {deal.dealname || deal.product_name || "(Unknown deal name)"}
            <br />
            <span className="text-sm text-gray-500">{deal.id + " "}</span>
          </li>
        ))}
    </ul>
  );
};
