import { createSlice } from "@reduxjs/toolkit";

const authReducer = createSlice({
  name: "auth",
  initialState: {
    // user: null,
    initialized: false,
    // token: localStorage.getItem("charging_token"),
  },
  reducers: {
    // setCredentials: (state, { payload: { token } }) => {
    //   state.token = token;
    //   state.initialized = true;
    // },
  },
  // went away when Clerk came around
  // extraReducers: (builder) => {
  //   builder.addMatcher(
  //     // on this particular call we get a token too
  //     api.endpoints.getDeal.matchFulfilled, // tap into the getDeal query fulfilled and write to this slice of state
  //     (state, { payload }) => {
  //       const { token } = payload;
  //       if (token) {
  //         storeToken(token);
  //         state.token = token;
  //       }
  //     }
  //   );
  // },
});

// export const { setCredentials } = authReducer.actions;
export default authReducer.reducer;
