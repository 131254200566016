export const smartyToInternalAddress = (address) => ({
  // these are hubspot "internal" keys both for _contact and product_
  address:
    (address.street_line || "") +
    (address.secondary ? " " + address.secondary : ""),
  city: address.city,
  state: address.state,
  zip: address.zipcode,
  country: address.country || "US",
});

export const getAddressOnly = (someObject = {}) => {
  const { address, state, zip, city, country } = someObject;
  return { address, state, zip, city, country };
};
