import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import StyledFormikField from "../components/StyledFormikField";
import useDebouncedInput from "../hooks/useDebouncedInput";
import AuthContext from "../store/AuthContext";
import {
  useGetTeamAccessQuery,
  useGetTeamMappingsQuery,
  useGetTeamsQuery,
  useMapTeamMutation,
} from "../store/api";
import TWdropdown from "../components/TWdropdown";

const LinkClickFunnelsIdToHubspot = () => {
  const formRef = useRef();

  const ctx = useContext(AuthContext);
  const {
    getClickFunnelsProduct,
    searchHubspotProducts,
    linkCfproductToHubspotProduct,
  } = ctx;

  const [hsEntries, setHsEntries] = useState([]); // text search entries (in _hubspot_)

  const [cfProduct, setCfProduct] = useState(null); // currently selected CF product

  const [cfProductId, setCfProductId] = useDebouncedInput();

  // debounced search field 1/2
  const lookupCFproductId = async (value) => {
    setCfProduct(() => null);
    // formRef.current.setFieldValue("clickFunnelsId", event.target.value);
    setCfProductId(value); // debounce
  };

  // debounced search field 2/2
  useEffect(() => {
    getClickFunnelsProduct(cfProductId)
      .then((cfprod) => {
        if (cfprod && cfprod.name) {
          setCfProduct(() => cfprod);
        } else {
          setCfProduct(() => null);
          // setCfProduct(() => ({ id: -1 }));
        }

        if (cfprod.hubspot_product_id) {
          formRef.current.setFieldValue("hubspotId", cfprod.hubspot_product_id);
          // something should be watching cfprod and search hubspot by product NAME fuzzy matching that (or prefilling a name search field and then search)
        } else {
          formRef.current.setFieldValue("hubspotId", "");
        }
      })
      .catch((err) => {
        console.log("getCF product err", err);
        setCfProduct(() => null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfProductId]);

  const [dbHsName, setDebHsName] = useDebouncedInput();
  // debounced search field 1/2
  const lookupHSname = async (value) => {
    // setCfProduct(() => null);
    // formRef.current.setFieldValue("hubspotName", event.target.value);
    setDebHsName(value);
  };
  // debounced search field 2/2
  useEffect(() => {
    if (!dbHsName || !dbHsName.trim()) {
      return;
    }
    searchHubspotProducts(dbHsName).then((hsproducts) => {
      console.log("got hsprod", hsproducts);
      if (hsproducts && hsproducts.length) {
        setHsEntries(() => hsproducts);
      } else {
        setHsEntries(() => []);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbHsName]);

  useEffect(() => {
    if (cfProduct && cfProduct.name) {
      console.log("got cfprod, search hubspot by text", cfProduct);
      formRef.current.setFieldValue("hubspotName", cfProduct.name);
      setDebHsName(cfProduct.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cfProduct]);

  const linkit = async (cf, hs) => {
    console.log("link it", cf, hs);
    await linkCfproductToHubspotProduct(cf, hs);
    setCfProduct((prev) => {
      prev.hubspot_product_id = hs;
      return prev;
    });
    setCfProductId(cf);
  };

  return (
    <div className="p-4 m-4 border rounded">
      <Formik
        innerRef={formRef}
        initialValues={{
          clickFunnelsId: "",
          hubspotId: "",
          hubspotName: "",
        }}
        validationSchema={Yup.object({
          clickFunnelsId: Yup.number()
            .typeError("Must be number")
            .positive("Must be positive"),
          hubspotId: Yup.number()
            .typeError("Must be number")
            .positive("Must be positive"),
        })}
      >
        {(form) => {
          const { values } = form;
          return (
            <Form>
              <div className="flex items-center gap-2 m-2">
                Clickfunnels Product # (like 3492394) :
                <StyledFormikField
                  name="clickFunnelsId"
                  tellChange={lookupCFproductId}
                />
              </div>
              {/* CF product info */}
              {cfProduct && (
                <div className="p-2 m-2 ">
                  Clickfunnels name:
                  {!cfProduct
                    ? " -PRODUCT ID NOT FOUND-"
                    : `"${cfProduct.name}"`}
                </div>
              )}
              <div className="flex items-center gap-2 m-2">
                Search Hubspot products by name (like Project next)
                <StyledFormikField
                  name="hubspotName"
                  tellChange={lookupHSname}
                />
              </div>

              <div className="flex items-center gap-2 m-2">
                Link to hubspot product #
                <StyledFormikField name="hubspotId" />
                <input
                  className="p-2 m-2 bg-gray-200 rounded cursor-pointer"
                  onClick={() =>
                    linkit(values.clickFunnelsId, values.hubspotId)
                  }
                  type="button"
                  value="Link"
                ></input>
              </div>
              {!!hsEntries.length && (
                <div className="p-2 font-bold">Found {hsEntries.length}</div>
              )}
              {hsEntries.map((entry) => (
                <div key={entry.id} className="p-2">
                  ID {entry.id}: &quot;{entry.properties?.name}&quot;{" "}
                  {!!cfProduct &&
                    (parseInt(cfProduct.hubspot_product_id) !==
                    parseInt(entry.id) ? (
                      <input
                        type="button"
                        className="p-2 m-2 bg-gray-200 rounded cursor-pointer"
                        onClick={() => linkit(values.clickFunnelsId, entry.id)}
                        value={`Link CF#${cfProduct.id} to HS#${entry.id}`}
                      ></input>
                    ) : (
                      <span className="font-bold">-SELECTED-</span>
                    ))}
                </div>
              ))}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

// TEAMS mgmt
const TeamEntry = (props) => {
  const { data = {} } = useGetTeamAccessQuery();
  const { options: teamAccess = [] } = data;

  const { name, selected, team } = props;

  const [mapTeam] = useMapTeamMutation();
  const selectEntry = (userGroupId) => {
    console.log(`set userlevel ${userGroupId} on team ${team}`);
    mapTeam({ userGroupId, team });
  };

  const augmentedEntries = [
    { id: null, label: "--no access--" },
    { id: 0, label: "ADMINISTRATOR" },
    ...teamAccess.map((team) => ({
      id: +team.value,
      label: team.label,
    })),
  ];

  return (
    <Formik>
      <Form className="flex justify-between gap-x-8">
        <span className="self-center ">
          Hubspot Main Team <span className="font-bold">{name}</span>
        </span>
        {/* maps to
        Hubspot Gatewayteam: SELECT {selected?.userlevel} {selected?.name} */}
        <TWdropdown
          entries={augmentedEntries}
          selected={{ id: selected?.userlevel, label: selected?.name }}
          onChange={selectEntry}
        />
        {/* <div className="flex justify-between">
        </div> */}
      </Form>
    </Formik>
  );
};

const currentMapped = (teamid, mappings) => {
  const found = mappings.find((m) => +m.hubspotteam === +teamid);
  return found;
};

const TeamsGroupMapping = () => {
  const { data: { teams: teams = [] } = {} } = useGetTeamsQuery();
  // const res = useGetTeamMappingsQuery();
  const { data: { mappings: teamMappings = [] } = {} } =
    useGetTeamMappingsQuery();

  return (
    <div className="p-4 m-4 border rounded">
      Map User Team (main) to userGroupId (gateway_team_access)
      <div className="flex flex-col items-start">
        <div>
          {teams.map((team) => {
            const selected = currentMapped(team.id, teamMappings);
            return (
              <TeamEntry
                key={team.id}
                team={team.id}
                name={team.name}
                selected={selected}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Admin = () => {
  return (
    <Box>
      <LinkClickFunnelsIdToHubspot />
      <TeamsGroupMapping />
    </Box>
  );
};
export default Admin;
