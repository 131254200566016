import { twMerge } from "tailwind-merge";

const Sticker = (props) => {
  const onClick = props.onClick || null;
  return (
    <div
      onClick={onClick}
      title={props.title}
      className={twMerge(
        "flex flex-row items-center justify-center py-1 px-2 border border-gray-300 rounded-md",
        onClick && "cursor-pointer",
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export default Sticker;
