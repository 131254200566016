import ClipLoader from "react-spinners/ClipLoader";
import Backdrop from "./Backdrop";

const Spinner = (props) => {
  return (
    <>
      <Backdrop />
      <div className="fixed flex flex-col items-center justify-center w-full h-full z-[2] pointer-events-none">
        <ClipLoader loading={true} color="white" />
        <div className="text-white">{props.text}</div>
      </div>
    </>
  );
};

export default Spinner;
