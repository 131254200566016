import Sticker from "./Sticker";
import { useNavigate } from "react-router-dom";
import useClerkInfo from "../hooks/useClerkInfo";
import { UserButton } from "@clerk/clerk-react";

// eslint-disable-next-line no-undef
const stagingServer = !!process.env.REACT_APP_stagingserver;
// eslint-disable-next-line no-undef
const sandbox = !!process.env.REACT_APP_sandbox;

const AdminBar = () => {
  const { user: clerkUser } = useClerkInfo();
  const { fullName } = clerkUser;

  const navigate = useNavigate();

  const goCashier = () => {
    navigate("/cashier");
  };

  return (
    <div className="sticky top-0 z-[1] flex flex-col bg-blue-400 shadow-lg min-h-14 text-white">
      <div className="flex flex-row p-2">
        {!!fullName && <Sticker className="border-none">{fullName}</Sticker>}

        <div className="flex justify-end flex-grow gap-2 ">
          <Sticker title="Cashier mode" onClick={goCashier}>
            Cashier
          </Sticker>
          <UserButton />
        </div>
      </div>
      {stagingServer && (
        <div className="sticky top-0 p-1 text-center text-white bg-red-500">
          WARNING - THIS IS THE DEVELOPMENT VERSION ({sandbox ? "sbx" : "prod"}
          ).{" "}
          <a href="https://gateway.mastermind.com" className="underline">
            Click here for the production version
          </a>
        </div>
      )}
    </div>
  );
};
export default AdminBar;
