import { Elements } from "@stripe/react-stripe-js";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import Box from "./Box";
import PaymentForm from "./PaymentForm";
import CashierMessage from "./CashierMessage";
import {
  SEGMENT_PAYMENT,
  SEGMENT_PAYMENTPLAN,
  stripePromise,
} from "../store/constants";
import ScheduledPaymentsPane from "./ScheduledPaymentsPane";
import SegmentButtons from "./SegmentButtons";
import PaymentPlanForm from "./PaymentPlanForm";
import { formatDollars } from "../util/stripe";
import { useSelector } from "react-redux";

// does this work?
// https://stackoverflow.com/questions/75135147/react-router-dom-v6-useblocker
import { unstable_useBlocker as useBlocker } from "react-router-dom";
function Prompt(props) {
  const block = props.when;
  useBlocker(() => {
    if (block) {
      return !window.confirm(props.message);
    }
    return false;
  });
  return <div key={block} />;
}

// the actual form must be wrapped in an Elements component containing the stripe promise.
const FormWrapper = () => {
  const { deal } = useSelector((state) => state.cashier);

  const [cleanForm, setCleanForm] = useState(true);

  const formFocusHandler = (clean) => {
    setCleanForm(clean);
  };

  const [selectedSegment, setSelectedSegment] = useState(SEGMENT_PAYMENT);
  const [message, setMessage] = useState(null);

  const chooseSegment = (segment) => {
    setSelectedSegment(() => segment);
  };

  const displayPane = (chosenSegment) => {
    switch (chosenSegment) {
      case SEGMENT_PAYMENTPLAN:
        return (
          <PaymentPlanForm
            formClean={formFocusHandler}
            setMessage={setMessage}
          />
        );
      case SEGMENT_PAYMENT:
      default:
        return (
          <PaymentForm formClean={formFocusHandler} setMessage={setMessage} />
        );
    }
  };

  return (
    <>
      <div className="flex w-full gap-3 px-4 mx-auto xl:container">
        {/* Sticky!? In case the right pane is much higher than the left, scroll only right pane */}
        <Box className="sticky w-full " outerClassName="w-6/12">
          {/* current amount */}
          {selectedSegment === SEGMENT_PAYMENT &&
            typeof deal?.amount !== "undefined" && (
              <div className="flex flex-row w-full text-gray-700">
                Deal current amount: {formatDollars(deal?.amount || 0)}
              </div>
            )}

          {/* Segment buttons */}
          <SegmentButtons
            chooseSegment={chooseSegment}
            selectedSegment={selectedSegment}
          />

          <Prompt
            when={!cleanForm}
            message={() =>
              "Are you sure to leave this page and lose the entered information?"
            }
          />

          <Elements stripe={stripePromise} className="flex flex-col">
            {displayPane(selectedSegment)}
            {message && (
              <CashierMessage
                className={twMerge(
                  " text-center ",
                  message.ok ? "text-green-500" : "text-red-500"
                )}
              >
                {message.text}
                {message.warningText && (
                  <div className="bg-yellow-100 border border-black ">
                    {message.warningText}
                  </div>
                )}
              </CashierMessage>
            )}
          </Elements>
        </Box>

        <Box className="w-full px-4 " outerClassName="w-6/12">
          <ScheduledPaymentsPane />
        </Box>
      </div>
    </>
  );
};

export default FormWrapper;
