import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon as SelectorIcon,
} from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { twMerge } from "tailwind-merge";

const TWdropdown = (props = {}) => {
  // expect entries {id,label}

  const { entries = [], selected, onChange } = props;

  const entryName = (id) => {
    const entry = entries.find((e) => e.id === (id ?? null));
    if (!entry) {
      return `-invalid (${id})-`;
    }
    return entry.label;
  };

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative mt-1 min-w-max">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">{entryName(selected?.id)}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg min-w-max max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {entries.map((entry) => {
                  return (
                    <Listbox.Option
                      key={entry.id}
                      className={({ active }) =>
                        twMerge(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={entry.id}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={twMerge(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {entry.label}
                          </span>

                          {selected ? (
                            <span
                              className={twMerge(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default TWdropdown;
