import { ErrorMessage } from "formik";
import { twMerge } from "tailwind-merge";

const StyledErrorMessage = (props) => (
  <div className={twMerge("pb-4 pt-2 text-red-600", props.className || '')}>
    <ErrorMessage {...props} />
  </div>
);

export default StyledErrorMessage;
