// https://stripe.com/docs/payments/accept-card-payments?platform=web&ui=elements&html-or-react=react
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../store/AuthContext";
import FieldGroup from "./FieldGroup";
import * as Yup from "yup";

import { twMerge } from "tailwind-merge";
import { Field, Form, Formik } from "formik";
import CardSelector from "./CardSelector";
import StyledFormikField from "./StyledFormikField";
import StyledErrorMessage from "./StyledErrorMessage";
import OverlayDialog from "./OverlayDialog";
import { ImCheckmark, ImCross, ImWarning } from "react-icons/im"; // stripe setup
import {
  cardInfoString,
  checkCardValidity,
  dateToIso8601YYYYMMDD,
  explainDecline,
  formatCents,
  iso8601YYYYMMDDtoLocalDate,
  ordinal_suffix_of,
  phoenixTime,
} from "../util/stripe";
import ProductDropdown from "./ProductDropdown";

import AddCard from "./AddCard";
import VerbalAgreement from "./VerbalAgreement";
import { CARD_ELEMENT_OPTIONS, LBL_PRODUCT } from "../store/constants";
import { addDays, addMonths } from "date-fns";
import AddressSelector from "./AddressSelector";
import useDebouncedInput from "../hooks/useDebouncedInput";
import { getAddressOnly } from "../util/smarty";

import { CalendarIcon as BsCalendarDate } from "@heroicons/react/20/solid";
// import { BsCalendarDate } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import {
  useChargeCentsMutation,
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useGetProductsQuery,
  useSchedulePaymentMutation,
} from "../store/api";
import { selectDeal, selectEmail } from "../store/cashier";
import TWDatePicker from "./TWDatePicker";

// eslint-disable-next-line no-undef
const sandbox = process.env.REACT_APP_sandbox || false;

const validateEntry = (entries, id) => {
  if (!id) {
    return id;
  }
  if (entries.map((entry) => String(entry.id)).includes(id)) {
    return id;
  }
  return ""; // no matching entry
};

const PaymentForm = (props) => {
  // bad card 5128 1234 5678 9010
  // ok card 5128 1234 5678 9011
  // 4242424242424242 succeed+process
  // 4000000000009995 fail insufficient_funds
  // 4000002500003155 authentication required (fail authentication_not_handled)

  const { setMessage } = props;

  const stripe = useStripe();
  const elements = useElements();

  const authctx = useContext(AuthContext);
  const { setOverlay, addCardToCustomer } = authctx;

  const dispatch = useDispatch();
  const [schedulePayment] = useSchedulePaymentMutation();
  const [chargeCents] = useChargeCentsMutation();

  const { deal, stripeCustomer, stripeCustomerId } = useSelector(
    (state) => state.cashier
  );
  const { user } = useSelector((state) => state.app);

  const { data: cards = [] } = useGetPaymentMethodsQuery(
    { customerId: stripeCustomerId },
    { skip: !stripeCustomerId }
  );
  const [deletePaymentMethod] = useDeletePaymentMethodMutation();

  const address = getAddressOnly(deal);

  const formRef = useRef();

  const defaultPaymentMethod =
    stripeCustomer?.invoice_settings?.default_payment_method ||
    stripeCustomer?.default_source ||
    ""; // new or old API
  // both Source ids (src_xxx) and Payment Method ids (pm_xxx) work as payment methods in the new API

  const [currentPaymentMethod, setCurrentPaymentMethod] =
    useState(defaultPaymentMethod);

  const [products, setProducts] = useState([]);

  const [doneDialog, setDoneDialog] = useState(false);

  const [addCardErr, setAddCardErr] = useState(null);

  const [previewTax, setPreviewTax] = useState(0);
  const tellTax = (taxDollars) => {
    setPreviewTax(() => taxDollars * 100);
  };

  // derived state
  const getproductbyid = (selected) => {
    return products.find((p) => p.id === selected);
  };

  let { preloadProduct } = props;
  const [selectedProduct, setSelectedProduct] = useState(preloadProduct || "");

  // handlers, functions
  const handleProductChange = (prod) => {
    setSelectedProduct(() => prod);
  };

  const handleCharge = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!stripe) {
      return;
    }

    if (formRef.current.values.schedule) {
      return scheduleCharge();
    }

    chargeNow();
  };

  const chargeNow = async () => {
    try {
      setMessage({
        ok: false,
        text: ``,
      });

      const customerId = stripeCustomer.id;

      let purchase;

      const amount = +formRef.current.values.amount * 100; // we pay in minimum fraction = cents
      const buyThisProduct = getproductbyid(selectedProduct);
      const productName = buyThisProduct.name;
      setOverlay(() => "Paying product…");

      // if we want to use customer balance with payment intents, it must be done manually:
      // need to check balance and
      // 1) "charge" all by negatively crediting user, if credit is large enough, or
      // 2) try charging the _difference_ only, and if successful, zero out the user credit balance.

      // this is direct full price purchase. Tax will be added by backend according to the supplied address and product
      const card = cards.find((c) => c.id === currentPaymentMethod).card;

      const { data: purchaseData, error: purchaseError } = await chargeCents({
        customerId,
        paymentMethodId: currentPaymentMethod,
        amount,
        description: productName,
        // deal_id: deal.id,
        // rep_id: user.repid,
        // product_id: selectedProduct,
        address,
        card_info: cardInfoString(card),
        customer_email: stripeCustomer.email,
        customer_name: stripeCustomer.name,
        term_description: "one off payment",
        has_tax: buyThisProduct.has_tax === "Yes",
        metadata: {
          deal_id: deal.id,
          rep_id: user.repid,
          product_id: selectedProduct,
          processor: "gateway",
        },
      });

      purchase = purchaseData;
      console.log("got payment", purchase);

      setOverlay(() => "");

      if (purchaseError) {
        throw new Error(JSON.stringify(purchaseError));
      }

      switch (purchase.status) {
        case "succeeded": {
          const msg = {
            ok: true,
            text: `The customer was successfully charged.`,
          };
          setMessage(msg); // form wrapper
          setDoneDialog(() => msg);
          break;
        }
        case "active": {
          const msg = {
            ok: true,
            text: `The customer was successfully charged, and the subscription is active.`,
          };
          setMessage(msg);
          setDoneDialog(msg);

          break;
        }
        default: {
          const msg = {
            ok: true,
            text: `The payment was approved with unknown status "${purchase.status}"`,
          };
          setMessage(msg);
          setDoneDialog(msg);
        }
      }
      clearForm(); // don't clear the ok/fail user messages
    } catch (ex) {
      const err =
        typeof ex?.message === "object" ? JSON.parse(ex.message) : ex.message;

      setOverlay(() => "");

      setMessage(() => ({
        ok: false,
        text: explainDecline(err),
      }));
    }
  };

  const scheduleCharge = async () => {
    // do this for x repetitions on addDate(firstchange, repetitions)

    try {
      setMessage({
        ok: false,
        text: ``,
      });
      const customerId = stripeCustomer.id;

      let purchase;

      const amount = +formRef.current.values.amount * 100; // we pay in minimum fraction = cents
      const repetitions = Math.round(+formRef.current.values.repetitions); // 1 or more
      const card = cards.find((c) => c.id === currentPaymentMethod).card;
      let scheduleTime = formRef.current.values.payment_date; // 1st

      // multiple schedules
      const warnings = [];
      let scheduledPayments = 0;
      const buyThisProduct = getproductbyid(selectedProduct);

      for (let rep = 1; rep <= repetitions; rep++) {
        const cardWillBeValid = checkCardValidity(
          card,
          addMonths(new Date(scheduleTime), rep - 1) // start by adding 0 months
        );

        let term_description =
          repetitions > 1 ? `Payment ${rep}/${repetitions}` : "One off payment";

        const payment_date = dateToIso8601YYYYMMDD(
          addMonths(iso8601YYYYMMDDtoLocalDate(scheduleTime), rep - 1)
        );
        const paymentData = {
          customer_id: customerId,
          subscription: false,
          rep_id: user.repid,
          deal_id: deal.id,
          product_id: selectedProduct,
          amount_cents: amount,
          product_name: buyThisProduct.name,
          payment_date,
          customer_email: stripeCustomer.email,
          customer_name: stripeCustomer.name,
          card_info: cardInfoString(card),
          payment_method: currentPaymentMethod,
          term_description,
          status: cardWillBeValid ? "scheduled" : "unscheduled",
          sandbox,
          has_tax: buyThisProduct.has_tax === "Yes",
          // term_description: "One time payment", // FIXME needs smarter text
        };

        // console.log("schedule", paymentData);
        setOverlay(() => `Scheduling ${term_description}`);

        const { data: purchaseresult, error: purchaseError } =
          await schedulePayment(paymentData);
        purchase = purchaseresult;

        if (!cardWillBeValid) {
          warnings.push(`The card will be invalid for ${term_description}`);
        }
        scheduledPayments++;

        if (purchaseError) {
          throw new Error(purchaseError.message);
        }
      }

      setOverlay(() => "");

      const dateOptions = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      };

      const dates =
        repetitions === 1
          ? `for ${new Date(purchase.payment_date).toLocaleDateString(
              "en-US",
              dateOptions
            )}`
          : `on the ${ordinal_suffix_of(
              +scheduleTime.split("-")[2]
            )} each month`;

      const taxMention =
        buyThisProduct.has_tax === "Yes"
          ? `Tax (approximately ${formatCents(
              previewTax
            )}) will be added to each charge.`
          : "No tax on this purchase.";

      let text = `${scheduledPayments}× ${formatCents(
        purchase.amount_cents
      )} payment${
        scheduledPayments > 1 ? "s" : ""
      } scheduled ${dates}, on card ${purchase.card_info}. ${taxMention}`;

      // add warnings here

      switch (purchase.status) {
        default: {
          const msg = {
            ok: true,
            text,
          };
          if (warnings.length) {
            msg.warningMessage = `NOTE: ${warnings.join(", ")}`;
          }
          setMessage(msg);
          setDoneDialog(msg);
        }
      }

      // clearForm(); // don't clear the ok/fail user messages
    } catch (err) {
      setOverlay(() => "");

      if (err.message.includes("error_on_requires_action")) {
        setMessage({
          ok: false,
          text: `Unsupported card. The card may be valid but requires 2 factor authentication. Try another card.`,
        });
      } else {
        setMessage({
          ok: false,
          text: `${err.message}`,
        });
      }
    }
  };

  const clearForm = (event) => {
    if (event) {
      event.preventDefault();
    }

    props.formClean(true);

    formRef.current.resetForm();
  };

  const clearFormButtonHandler = (event) => {
    if (event) {
      event.preventDefault();
    }
    setMessage({ ok: true, text: "" }); // no message is … no message
    clearForm();
  };
  const [debouncedAmount, setDebouncedAmount] = useDebouncedInput();

  const [calendarOpen, setCalendarOpen] = useState(false);

  const { userGroupId } = user ?? {};
  const { data: { stripePrices: rawProducts } = {} } = useGetProductsQuery({
    userGroupId, // Products available may change
  });

  useEffect(() => {
    const newProds = (rawProducts ?? []).filter(
      (p) => !p.hs_recurring_billing_period
    );
    setProducts(() => newProds);

    // if product list changes and the selected product is no longer there
    if (newProds && newProds.length && selectedProduct) {
      const validatedProduct = validateEntry(newProds, selectedProduct);
      if (!validatedProduct) {
        // console.log("INVALID PRODUCT specified in url, setting to null");
        setSelectedProduct(() => "");
      }
    }

    // figure and auto select productSET => productID dropdown
    if (deal?.line_item_product_id) {
      const productSet = newProds.find(
        (p) => p.id === deal.line_item_product_id
      )?.product_id;

      const groupProduct = newProds.filter(
        (p) => p.product_id === productSet && !p.hs_recurring_billing_period
      )[0]?.id;

      setSelectedProduct(() => "" + groupProduct);
    }
  }, [rawProducts]);

  const [chargeTax, setChargeTax] = useState(false);

  // watch product selection
  useEffect(() => {
    const selectedProd = getproductbyid(selectedProduct);
    setChargeTax(() => selectedProd?.has_tax === "Yes");
    if (!selectedProd?.product_id) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const addCard = async (event) => {
    setAddCardErr(() => null);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    try {
      const card = elements.getElement(CardElement);

      const addedPaymentMethod = await addCardToCustomer(
        stripeCustomer.name,
        card,
        stripe
      );

      setCurrentPaymentMethod(() => addedPaymentMethod);
    } catch (ex) {
      const err = JSON.parse(ex.message);
      setAddCardErr(() => explainDecline(err));
    } finally {
      setOverlay(() => "");
    }
  };

  const removeCard = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    try {
      setOverlay(() => "Removing card");
      await deletePaymentMethod({ id: currentPaymentMethod });
    } finally {
      setOverlay(() => "");
    }
  };

  const scheduleToggled = (event) => {
    const newstate = event.target.checked;

    formRef.current.setFieldValue("schedule", newstate);
    setCalendarOpen(() => newstate);
  };

  const clearCustomerSelection = () => {
    dispatch(selectEmail(""));
    dispatch(selectDeal(null));
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          amount: "",
          agree: "",
          addCredit: false,
          creditAmount: 0,
          schedule: false,
          repetitions: 1,
        }}
        validationSchema={Yup.object({
          amount: Yup.number()
            .typeError("Must be number")
            .positive("Must be positive")
            .required("Amount is required")
            .min(0.5, "Minimum 50 cents"),

          creditAmount: Yup.number()
            .typeError("Must be number")
            .positive("Must be positive")
            .min(0.01, "Must be at least 1 cent"),

          agree: Yup.boolean().oneOf([true]),
          schedule: Yup.boolean().oneOf([true, false]),
          addCredit: Yup.boolean().oneOf([true, false]),
          payment_date: Yup.string(),
          repetitions: Yup.number()
            .typeError("Must be number")
            .positive("Must be positive")
            .integer("Must be whole numbers")
            .min(1, "Must be at least 1 charge"),
        })}
      >
        {(form) => {
          const { errors, touched, values } = form;

          // check if this card will be valid in the future
          touched.payment_date = true;

          const cardWillBeValid = checkCardValidity(
            cards.find((c) => c.id === currentPaymentMethod)?.card,
            addMonths(
              new Date(formRef.current?.values?.payment_date),
              values.repetitions - 1
            )
          );

          // form internal, set payment_date form field. gets Date object from datepicker
          const setDate = (datestr) => {
            form.setFieldValue("payment_date", datestr);
          };

          const chargeDisabled =
            !values.agree ||
            (values.schedule &&
              values.repetitions < 2 &&
              (typeof values.payment_date === "undefined" ||
                !cardWillBeValid)) || // ass-u-me datepicker will ensure only future dates. Only barf when it's the only payment that can fail
            (values.addCredit &&
              (errors.creditAmount || values.creditAmount < 0.01)) ||
            !selectedProduct ||
            !currentPaymentMethod ||
            !cards.find((e) => e.id === currentPaymentMethod) ||
            errors.repetitions ||
            currentPaymentMethod === "new" ||
            errors.amount ||
            (chargeTax && !address?.address) || // only require address if we are charging tax on selected product
            (!values.amount && !touched.amount);

          // Products dropdown
          const listProducts = products.filter(
            (p) => !p.hs_recurring_billing_period
          );

          // Prices dropdown
          // list calculated in state

          const repetitionText =
            values.repetitions > 1
              ? ` and thereafter on the ${ordinal_suffix_of(
                  +(values.payment_date?.split("-") || [])[2]
                )} each month`
              : "";

          const chargeText = values.schedule
            ? `Charged on ${iso8601YYYYMMDDtoLocalDate(
                values.payment_date
              ).toLocaleDateString(undefined, {
                day: "numeric",
                month: "short",
              })}${repetitionText}`
            : "Charged today";

          const setAmount = (value) => {
            // formRef.current.setFieldValue("amount", event.target.value);
            setDebouncedAmount(value || 0);
          };

          // we get goodies from Formik that we can use here
          return (
            <Form className="px-4" onSubmit={handleCharge}>
              <ProductDropdown
                label={LBL_PRODUCT}
                selected={selectedProduct}
                onChange={handleProductChange}
                products={listProducts}
                labelWithPrice
              />

              <div className="mt-4 mb-2 text-blue-400">Pay with</div>
              <CardSelector
                name="card"
                cards={cards}
                paymentMethod={currentPaymentMethod}
                setCurrentSource={setCurrentPaymentMethod}
                removeCard={removeCard}
              />

              <div className={currentPaymentMethod !== "new" ? " hidden" : ""}>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
              </div>

              {/* Add card component */}
              {!!(currentPaymentMethod === "new") && (
                <AddCard onAdd={addCard} />
              )}
              {addCardErr && (
                <div className="text-center text-red-500">{addCardErr}</div>
              )}

              <div className={twMerge("flex flex-col")}>
                <label htmlFor="deal" className="my-1 mt-4 text-blue-400 ">
                  Amount
                </label>
                <StyledFormikField
                  type="text"
                  className={twMerge("p-2")}
                  name="amount"
                  tellChange={setAmount}
                />
                {errors.amount && <StyledErrorMessage name="amount" />}
              </div>

              {/* Schedule payments input field */}
              <div className="mt-4 mb-2 text-blue-400">
                Schedule new payment
              </div>
              <div className="flex flex-col ">
                <div className="flex flex-row items-center pt-2 text-sm text-justify text-gray-400">
                  <div className="flex-grow text-left">
                    <Field
                      type="checkbox"
                      id="scheduledCheckbox"
                      name="schedule"
                      className="px-1 cursor-pointer mx1"
                      onChange={scheduleToggled}
                    />
                    <label
                      htmlFor="scheduledCheckbox"
                      className="px-1 mx-1 cursor-pointer "
                    >
                      {`Schedule payment ${
                        values.schedule && values.repetitions > 1
                          ? "starting: " +
                            (values.payment_date || "(select a date)")
                          : values.schedule
                          ? "for: " + (values.payment_date || "(select a date)")
                          : "for a later date"
                      }`}
                    </label>
                  </div>
                  {values.schedule && (
                    <div
                      className="cursor-pointer"
                      onClick={() => setCalendarOpen(() => !calendarOpen)}
                    >
                      <BsCalendarDate className="w-5 h-5" aria-hidden="true" />
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  {calendarOpen && (
                    <TWDatePicker
                      className="self-center"
                      onChange={setDate}
                      onClose={() => setCalendarOpen(() => false)}
                      minDate={addDays(phoenixTime(), 1)}
                      defaultDate={values.payment_date}
                    />
                  )}
                </div>
              </div>

              {!cardWillBeValid && currentPaymentMethod !== "new" && (
                <div className="flex p-2 my-2 text-center text-amber-700 bg-amber-100">
                  <ImWarning />
                  The selected card will be invalid for one or more of the
                  installments.
                </div>
              )}

              {values.schedule && (
                <div className={twMerge("flex flex-col")}>
                  <label htmlFor="deal" className="my-1 mt-4 text-blue-400 ">
                    {`Monthly repetitions on the ${ordinal_suffix_of(
                      +(values.payment_date?.split("-") || [])[2]
                    )} each
                  month`}
                  </label>
                  <StyledFormikField
                    type="text"
                    className={twMerge("p-2")}
                    name="repetitions"
                  />
                  {errors.repetitions && (
                    <StyledErrorMessage name="repetitions" />
                  )}
                </div>
              )}

              {deal && (
                <div className="flex flex-row w-full my-2 text-gray-700">
                  Remaining balance:{" "}
                  {formatCents(
                    parseInt(getproductbyid(selectedProduct)?.priceCents || 0) -
                      values.amount * 100 -
                      parseFloat(deal?.amount || 0) * 100
                  )}
                </div>
              )}

              {/* Address and tax display */}
              <AddressSelector
                product={getproductbyid(selectedProduct)}
                chargeText={chargeText}
                chargeAmount={debouncedAmount}
                tellTax={tellTax}
              />

              <VerbalAgreement />

              <FieldGroup className="centered">
                <button
                  disabled={chargeDisabled}
                  className={twMerge(
                    "w-full p-2 my-2 font-semibold text-white ",
                    chargeDisabled ? "bg-gray-400" : "bg-blue-400"
                  )}
                >
                  {values.schedule ? "Schedule payment" : "Charge"}
                </button>
              </FieldGroup>
              {doneDialog && (
                <OverlayDialog
                  onYes={() => setDoneDialog(() => false)}
                  onNo={clearCustomerSelection}
                  ok={doneDialog.ok}
                  icon={doneDialog.ok ? ImCheckmark : ImCross}
                  message={doneDialog.text}
                  warningMessage={doneDialog.warningMessage}
                  message2={"Keep working with this customer?"}
                />
              )}

              <FieldGroup className="centered">
                <button
                  className="w-full p-2 my-2 font-semibold text-blue-400 border border-blue-500 rounded "
                  onClick={clearFormButtonHandler}
                >
                  Clear
                </button>
              </FieldGroup>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PaymentForm;
