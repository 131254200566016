import { useContext, useState } from "react";
import { twMerge } from "tailwind-merge";
import AuthContext from "../store/AuthContext";
import FieldGroup from "./FieldGroup";

import "react-toastify/dist/ReactToastify.css";
import PortalLink from "./PortalLink";
import { useSelector } from "react-redux";
import { useLazyGetPortalLinkQuery } from "../store/api";

const AddCard = (props) => {
  const { onAdd } = props;

  const authctx = useContext(AuthContext);
  const { setOverlay } = authctx;

  const { deal, stripeCustomer } = useSelector((state) => state.cashier);

  const [paymentLink, setPaymentLink] = useState(null);

  const [requestPortalLink] = useLazyGetPortalLinkQuery();

  const getPortalLink = async (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setOverlay("Getting customer link…");

    const result = await requestPortalLink(
      { customerId: stripeCustomer.id },
      false
    ).unwrap();

    setPaymentLink(() => result);
    setOverlay("");
  };

  return (
    <FieldGroup className="mb-4 centered">
      <button
        className="w-full p-2 my-2 font-semibold text-white bg-blue-400"
        onClick={onAdd}
      >
        Add card
      </button>
      <div
        className={twMerge(
          "",
          paymentLink && "border border-black p-2 rounded"
        )}
      >
        <button
          className="w-full p-2 my-2 font-semibold text-white bg-gray-400"
          onClick={getPortalLink}
        >
          Customer link to update payment info
        </button>
        {paymentLink && (
          <PortalLink
            customer={stripeCustomer}
            deal={deal}
            link={paymentLink}
            onClose={() => setPaymentLink(() => null)}
          />
        )}
      </div>
    </FieldGroup>
  );
};
export default AddCard;
