/* eslint-disable no-unused-vars */
import { createContext } from "react";

const ctx = createContext({
  autocompleteAddress: ({ search, selected }) => Promise({}),
  addCardToCustomer: (customerName, card, stripe) => Promise({}),
  overlay: {},
  setOverlay: (text) => {},

  // ADMIN stuff should be reduxified
  getClickFunnelsProduct: (cfProductId) => Promise({}),
  searchHubspotProducts: (fuzzyname) => Promise({}),
  linkCfproductToHubspotProduct: (cf, hs) => Promise({}),
});
export default ctx;
