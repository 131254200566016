import { useAuth, useUser } from "@clerk/clerk-react";

const useClerkInfo = () => {
  const clerkUser = useUser();
  // console.log("clerkuser", clerkUser);

  const clerkAuth = useAuth();
  // console.log("clerkauth", auth);
  const { signOut } = clerkAuth;

  const { user: c_user } = clerkUser || {};
  const { fullName, publicMetadata: meta } = c_user || {};

  const user = {
    fullName,
    meta,
  };

  const hasRole = (client, role) => {
    if (!user?.meta?.roles || !user?.meta?.roles[client]) {
      return false;
    }
    return user.meta.roles[client].includes(role);
  };

  const loggedin = true;

  return {
    user,
    signOut,
    hasRole,
    loggedin,
  };
};

export default useClerkInfo;
