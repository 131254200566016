import { SignIn } from "@clerk/clerk-react";

const SignInPage = () => (
  <div className="w-full flex">
    <div className="mt-12 mx-auto">
      <SignIn
        appearance={{
          elements: {
            footerAction: { display: "none" },
          },
        }}
      />
    </div>
  </div>
);
export default SignInPage;
