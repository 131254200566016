import { twMerge } from "tailwind-merge";

const Box = (props) => {
  return (
    <div
      className={twMerge("flex flex-col ", props.outerClassName)}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
    >
      <div
        className={twMerge(
          "p-8 mx-auto my-2 bg-white border border-gray-300 border-solid rounded-md  w-6/12 ",
          props.className
        )}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Box;
