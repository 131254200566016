import { isAfter, differenceInDays } from "date-fns";
import { BsPencilSquare } from "react-icons/bs";
import { HiOutlineDuplicate } from "react-icons/hi";
import { IoReload, IoTrash } from "react-icons/io5";
import { twMerge } from "tailwind-merge";
import {
  dt,
  formatCents,
  localTimeFromUTCtime,
  phoenixTime,
} from "../../util/stripe";
import { useGetPaymentMethodsQuery } from "../../store/api";
import PaymentStatus from "./PaymentStatus";
import { MdWarning } from "react-icons/md";

const PaymentEntry = (props) => {
  const { payment, setPauseDialog, editPayment, productName } = props;
  const { deal_id, payment_method, customer_id } = payment;
  const { data: cards = [] } = useGetPaymentMethodsQuery({
    customerId: customer_id,
  });
  const has_card = cards.map((method) => method.id).includes(payment_method);

  // to evaluate "future?" we should get date in PHOENIX TIMEZONE
  const now = phoenixTime();

  const paymentDateVal = dt(payment.payment_date);

  const paidToday = !differenceInDays(now, paymentDateVal);

  const paymentDate = localTimeFromUTCtime(paymentDateVal); // local rep, not phoenix nor utc.
  const past = isAfter(now, paymentDate);

  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    <div
      className={twMerge(
        "flex flex-row justify-between px-4",
        (past || payment.status === "success") && "text-gray-400",
        paidToday && "bg-yellow-50"
      )}
      key={"scheduledPayment_" + payment.id}
    >
      {(!!payment.retry || payment.status === "failed") &&
        payment.countEntries > 1 && (
          <div
            className={twMerge(
              "w-[15px] bg-red-100 mr-4 ",
              payment.settled && "bg-green-100"
            )}
          ></div>
        )}
      <div className="flex w-full mb-4">
        {/* left block: Date, reason, payment method */}
        <div className="w-full mt-3">
          <b>{paymentDate.toLocaleDateString("en-US", dateOptions)}</b>
          {payment.dateDifference ? ` (${payment.dateDifference})` : ""}

          {" " + payment.term_description}
          <p title={`Deal ${deal_id}`}>
            <i>for</i>
            {` ${productName}`}
            {/* {" " + payment.product_name} */}
          </p>
          <p>
            <i>on</i>
            {" " + payment.card_info}
            {!has_card && payment.status !== "success" && (
              <div className="text-red-500 flex gap-1 f">
                <MdWarning />
                <small>Not in the list of current cards</small>
              </div>
            )}
          </p>
        </div>

        {/* right block: Amount, status and Tools */}
        <div className="flex flex-col align-center">
          <div
            className="flex justify-end"
            title={
              past
                ? payment.tax_added
                  ? `Including tax ${formatCents(
                      payment.tax_added
                    )}. Net was ${formatCents(payment.amount_cents)}`
                  : "No tax added"
                : "Tax will be added when charged" //
            }
          >
            {formatCents(+payment.amount_cents + (payment.tax_added || 0))}
          </div>
          {past || payment.status === "success" ? (
            <div className="flex flex-col items-end">
              {payment.settled ? (
                <HiOutlineDuplicate
                  onClick={() => props.onCopy(payment)}
                  className="mx-1 cursor-pointer"
                />
              ) : (
                <IoReload
                  onClick={() => props.onCopy(payment)}
                  className="mx-1 cursor-pointer"
                />
              )}
              <PaymentStatus payment={payment} />
            </div>
          ) : (
            <div className="flex flex-col items-end">
              <div className="flex ">
                <HiOutlineDuplicate
                  onClick={() => props.onCopy(payment)}
                  className="mx-1 cursor-pointer"
                />
                <BsPencilSquare
                  color="blue"
                  className="mx-1 cursor-pointer"
                  onClick={() => editPayment(payment)}
                />
                <IoTrash
                  title="Delete this payment"
                  onClick={() =>
                    setPauseDialog(() => ({
                      id: payment.id,
                      newScheduledState: false,
                    }))
                  }
                  className="mx-1 text-red-500 cursor-pointer"
                />
              </div>
              <PaymentStatus payment={payment} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentEntry;
