import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

const Backdrop = (props) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const [mouseDown, setMouseDown] = useState(false);
  const mousedown = () => {
    setMouseDown(() => true);
  };

  const mouseup = () => {
    if (mouseDown && props.onClick) {
      props.onClick();
    }
    setMouseDown(() => false);
  };

  const mouseLeave = () => {
    setMouseDown(() => false)
  }

  return (
    <>
      <div
        className={twMerge(
          "flex fixed w-full h-full bg-black/80 z-[2]",
          props.className
        )}
        onMouseDown={mousedown}
        onMouseUp={mouseup}
        onMouseOut={mouseLeave}
      >
        {props.children}
      </div>
    </>
  );
};

export default Backdrop;
