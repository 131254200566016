import { twMerge } from "tailwind-merge";
import { useDispatch, useSelector } from "react-redux";
import { selectDeal, selectEmail } from "../store/cashier";
import {
  useGetDealsForEmailQuery,
  useGetHubspotContactQuery,
} from "../store/api";
import { fullName } from "../util/stripe";
import Breadcrumb from "./Breadcrumb";

import Sticker from "./Sticker";
import { NavLink, useNavigate } from "react-router-dom";
import useClerkInfo from "../hooks/useClerkInfo";
import { UserButton } from "@clerk/clerk-react";

// eslint-disable-next-line no-undef
const stagingServer = !!process.env.REACT_APP_stagingserver;
// eslint-disable-next-line no-undef
const sandbox = !!process.env.REACT_APP_sandbox;

const TopBar = () => {
  const { user: clerkUser, hasRole, loggedin } = useClerkInfo();
  const isAdmin = hasRole("charging", "admin");

  const { fullName: userName } = clerkUser;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deal, selectedEmail } = useSelector((state) => state.cashier);

  const { data: contact = {} } = useGetHubspotContactQuery(
    { email: selectedEmail },
    { skip: !selectedEmail }
  );
  const selectedName = fullName(contact);

  const { data: deals = [] } = useGetDealsForEmailQuery(
    { email: selectedEmail },
    { skip: !selectedEmail }
  );

  const deselectDeal = () => {
    dispatch(selectDeal(null));
  };

  const goAdmin = () => {
    navigate("/admin");
  };

  const deselectCustomer = () => {
    if (selectedEmail) {
      dispatch(selectDeal(null));
      dispatch(selectEmail(null));
      return;
    }
  };

  return (
    <div className="sticky top-0 z-[1] flex flex-col bg-blue-400 shadow-lg min-h-14 text-white">
      <div className="flex flex-row p-2">
        {!!userName && (
          <Sticker className={twMerge("border-none")}>{userName}</Sticker>
        )}

        {/* New redux based layout: > Customer name|email > Deal */}
        {!!selectedEmail && (
          <Breadcrumb
            onClick={deselectCustomer}
            onClickText={deal?.id ? deselectDeal : null}
            text={selectedName || selectedEmail}
          />
        )}

        {deal?.id && (
          <Breadcrumb
            onClick={deselectDeal}
            text={
              "Deal " +
              deal?.id +
              (deals?.length
                ? ` (${
                    deals?.length === 1 ? "only deal" : "of " + deals.length
                  })`
                : "")
            }
          />
        )}

        <div className="flex justify-end flex-grow gap-2 ">
          {isAdmin ? (
            <Sticker title="Admin mode" onClick={goAdmin}>
              ADM
            </Sticker>
          ) : null}
          {loggedin ? (
            <UserButton
              afterSignOutUrl="/"
              appearance={{
                elements: {
                  userButtonPopoverActionButton__manageAccount: {
                    display: "none",
                  },
                },
              }}
            />
          ) : (
            <div className="flex flex-row p-2">
              <NavLink
                to="/login"
                className="flex flex-row items-center justify-center px-2 py-1 mx-2 text-white border border-gray-300 rounded-md"
              >
                Login
              </NavLink>

              <NavLink
                to="register"
                className="flex flex-row items-center justify-center px-2 py-1 mx-2 text-white border border-gray-300 rounded-md"
              >
                Register
              </NavLink>
            </div>
          )}
        </div>
      </div>
      {stagingServer && (
        <div className="sticky top-0 p-1 text-center text-white bg-red-500">
          WARNING - THIS IS THE DEVELOPMENT VERSION ({sandbox ? "sbx" : "prod"}
          ).{" "}
          <a href="https://gateway.mastermind.com" className="underline">
            Click here for the production version
          </a>
        </div>
      )}
    </div>
  );
};
export default TopBar;
