import { createSlice } from "@reduxjs/toolkit";

const appReducer = createSlice({
  name: "app",
  initialState: {
    user: {
      access: false,
      name: "",
      userGroupId: null,
      teamId: null,
      repid: null,
    },
  },
  reducers: {
    setAppMode: (state, action) => {
      const { payload } = action;
      state.mode1 = payload;
    },
    setUser: (state, action) => {
      const { payload } = action;
      const user = { ...state.user };
      state.user = { ...user, ...payload };
    },
  },
  // extraReducers: (builder) => {
  //   builder.addMatcher(
  //     api.endpoints.getDeal.matchFulfilled, // tap into the getDeal query fulfilled and write to this slice of state
  //     (state, { payload }) => {
  //       const { token } = payload;
  //       if (token) {
  //         const usr = decodeToken(token);
  //         state.user.userGroupId = usr.userGroupId;
  //         state.user.teamId = usr.teamId;
  //       }
  //     }
  //   );
  // },
});

export const { setAppMode, setUser } = appReducer.actions;
export default appReducer.reducer;
